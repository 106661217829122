import React from "react";
import Skill from "./skill";
import './allSkills.css'

const AllSkills = () => {
return (
	<section>
                <div class="container">
                        <h2>Mes Compétences</h2>
                        <div class="md-spacer"></div>
                        <div class="all-skills">
                                <Skill delay="0" name="Figma" image="https://cdn-icons-png.flaticon.com/512/5968/5968705.png"/>
                                <Skill delay="100" name="Procreate" image="https://e7.pngegg.com/pngimages/714/677/png-clipart-computer-software-drawing-innovative-buttons-computer-computer-wallpaper.png"/>
                                <Skill delay="200" name="React" image="https://cdn-icons-png.flaticon.com/512/1260/1260775.png"/>
                                <Skill delay="300" name="Angular" image="https://cdn.iconscout.com/icon/free/png-256/angular-2038881-1720094.png"/>
                                <Skill delay="400" name="Flutter" image="https://user-images.githubusercontent.com/51419598/152648731-567997ec-ac1c-4a9c-a816-a1fb1882abbe.png"/>
                                <Skill delay="500" name="Ionic" image="https://dimitridessus.fr/assets/img/skills/frameworks/ionic.svg"/>
                                <Skill delay="600" name="JS" image="https://cdn-icons-png.flaticon.com/512/5968/5968292.png"/>
                                <Skill delay="700" name="Html/css" image="https://cdn-icons-png.flaticon.com/512/174/174854.png"/>  
                                <Skill delay="800" name="PHP" image="https://icons-for-free.com/download-icon-php+plain-1324760555060951567_512.png"/>  
                                <Skill delay="900" name="Wordpress" image="https://pngimg.com/uploads/wordpress/wordpress_PNG43.png"/>  
                                <Skill delay="1000" name="Webflow" image="https://uploads-ssl.webflow.com/5fd386f520057b2464ffd1bf/5ffc8455fc57c21985d72e76_webflow.png"/>  
                        </div>
                </div>
	</section>
);
};

export default AllSkills;
