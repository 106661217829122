import React from "react";
import BlankPage from "../../blankpage/BlankPage";
import MobileTile from "./MobileTile";
import popcorn from "../../../assets/images/portfolio/popcorn.jpg";

function MobileList() {
  
    return (
      <section>
        <div class="container">
          <div class="tiles-grid">
            <MobileTile image={popcorn} title="Popcorn biblioteque films et series" url="#"/>
          </div>

        </div>
      </section>
    );
  }

export default MobileList;