import React from "react";
import { Accessibility } from "../navbar/accessibility";
import './footer.css'

const Footer = () => {
    return (
        <footer>
            <div class="container">
                <div class="divider"></div>
            </div>
            <div class="spacer"></div>
            <div class="container">
                <div class="footer_row">
                    <div class="footer_column">
                        <div class="footer_text"><a class="text-uppercase" href="mailto:hello@brudoerdison.com">mercierjulien149@gmail.com</a></div>
                    </div>
                    <div class="footer_column_center">
                        <p class="footer_text">© 2022 <span>Julien Mercier</span>. All Rights Reserved.</p>
                    </div>
                    <div class="footer_column">
                        <Accessibility />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
