import React from "react";
import './experiences.css'

const Experiences = () => {
    return (
        <section>
            <div class="container">
                <h2>Mes Expériences</h2>
                <div class="row">
                    <div class="xp_timeline" data-aos="fade" data-aos-delay="0">
                        <div class="xp_year">2018
                        </div>
                        <ul class="xp_list">
                            <li class="xp_item">
                                <h4 class="xp_title">Obtention du Brevet
                                </h4>
                                <p class="xp_description">Collège Claudie Haigneré
                                </p>
                            </li>
                            <li class="xp_item">
                                <h4 class="xp_title">Concours Castor
                                </h4>
                                <p class="xp_description">Quart de final
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="xp_timeline" data-aos="fade" data-aos-delay="100">
                        <div class="xp_year">2019 - 2021
                        </div>
                        <ul class="xp_list">
                            <li class="xp_item">
                                <h4 class="xp_title">Conception Sites Web
                                </h4>
                                <p class="xp_description">Freelancer
                                </p>
                            </li>
                            <li class="xp_item">
                                <h4 class="xp_title">Baccalauréat
                                </h4>
                                <p class="xp_description">Lycée Jean Vilar, Villeneuve-les-Avignon
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="xp_timeline" data-aos="fade" data-aos-delay="200">
                        <div class="xp_year">2021 - Present
                        </div>
                        <ul class="xp_list">
                            <li class="xp_item">
                                <h4 class="xp_title">Etudiant Master informatique
                                </h4>
                                <p class="xp_description">Epsi, Montpellier
                                </p>
                            </li>
                            <li class="xp_item">
                                <h4 class="xp_title">Web Designer
                                </h4>
                                <p class="xp_description">Freelancer
                                </p>
                            </li>
                            <li class="xp_item">
                                <h4 class="xp_title">Developpeur Web
                                </h4>
                                <p class="xp_description">Agence Appy-web
                                </p>
                            </li>
                            <li class="xp_item">
                                <h4 class="xp_title">Developpeur Mobile
                                </h4>
                                <p class="xp_description">Freelancer
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experiences;
