import React from "react";
import portrait from "../../assets/images/portrait.png";
import cv from "../../assets/images/cv.png";
import cvpdf from "../../assets/cv_mercier_julien.pdf";
import CountUp from 'react-countup';


const HomeAboutMe = () => {
	return (
		<section>
			<div class="container">
				<div class="row">

					<div class="portraitContainer flip-container">
						<div class="flipper">
							<div clas="front">
								<img class="portraitImg" src={portrait} alt="Julien Mercier"></img>
							</div>
							<div class="back">
								<a href={cvpdf} download>
									<img class="portraitImg" src={cv} alt="Julien Mercier"></img>
								</a>
							</div>
						</div>
					</div>

					<div class="informationContainer">

						<div class="InformationBlock" data-aos="fade" data-aos-delay="100">
							<h5>Biographie</h5>

							<div class="sm-spacer" ></div>

							<p>Ne programme pas par choix, mais par amour ! Je suis Julien, un développeur fullstack basé à Montpellier</p>
						</div>

						<div class="md-spacer"></div>

						<div class="" data-aos="fade" data-aos-delay="200">
							<h5>Contact</h5>

							<div class="sm-spacer" ></div>

							<p>Montpellier, France <br></br>
								<a href="mailto:mercierjulien149@gmail.com">mercierjulien149@gmail.com</a><br></br>
								<a href="tel:+33767554161">+33 7 67 55 41 61</a>
							</p>
						</div>

						<div class="md-spacer"></div>

						<div class="" data-aos="fade" data-aos-delay="300">
							<h5>Services</h5>

							<div class="sm-spacer" ></div>

							<p>UI/UX Design <br></br>Web / Mobile <br></br>Frontend & Backend <br></br> Création design</p>
						</div>
					</div>

					<div class="numberContainer">

						<div class="" data-aos="fade" data-aos-delay="100">
							<h5>Année <br></br>D'experience</h5>

							<div class="sm-spacer" ></div>

							<div class="">
								<div class="numberText"><CountUp end={3} /></div>
							</div>
						</div>

						<div class="md-spacer" ></div>

						<div class="" data-aos="fade" data-aos-delay="200">
							<h5>Clients <br></br>Satisfaits</h5>

							<div class="sm-spacer" ></div>

							<div class="">
								<div class="numberText"><CountUp end={100} />%</div>
							</div>
						</div>

						<div class="md-spacer"></div>

						<div class="" data-aos="fade" data-aos-delay="300">
							<h5>Clients dans le monde</h5>

							<div class="sm-spacer" ></div>

							<div class="">
								<div class="numberText"><CountUp end={10} />+</div>
							</div>
						</div>

						<div class="md-spacer"></div>

						<div class="" data-aos="fade" data-aos-delay="400">
							<h5>Projets réalisés</h5>

							<div class="sm-spacer" ></div>

							<div class="">
								<div class="numberText count" data-prefix="" data-suffix=""><CountUp end={14} /></div>

							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
	);
};

export default HomeAboutMe;
