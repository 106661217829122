import React from "react";

function WebsiteTile(props) {

    const image = props.image;
    const title = props.title;
    const url = props.url;

return (
	<div class="tile">
        <a href={url} target="blank">
            <div class="image-box"><img src={image} class="tile-img"></img></div>
            <div class="descriptions">
                <h4>{title}</h4>
            </div>
            
        </a>

	</div>
);
};

export default WebsiteTile;
