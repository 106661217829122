import React from "react";
import styled from "styled-components";
import MyLogoImg from "../../assets/images/logo.svg";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LogoImg = styled.div`
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoText = styled.h2`
  font-size: 16px;
  margin: 0;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: center;
`;

export function Logo(props) {
  return (
    <LogoWrapper>
      <LogoImg>
        <img src={MyLogoImg} alt="Mercier Julien" />
      </LogoImg>
      <LogoText>Julien <br></br> Mercier</LogoText>
    </LogoWrapper>
  );
}
