import React from "react";
import WebsiteTile from "./websiteTile";
import pubfinder from "../../../assets/images/portfolio/pubfinder.jpg";
import appyweb from "../../../assets/images/portfolio/appyweb.jpg";
import fake from "../../../assets/images/portfolio/fake.jpg";
import ozen from "../../../assets/images/portfolio/ozenmassage.jpg";

function WebsitesList() {
  
    return (
      <section>
        <div class="container">
          <div class="tiles-grid">
            <WebsiteTile image={fake} title="FAKE Sensibilisation aux fakes news" url="#"/>
            <WebsiteTile image={appyweb} title="Appy Web agence conception Web" url="https://appy-web.fr/"/>
            <WebsiteTile image={pubfinder} title="Pubfinder Beer & Cocktail" url="https://juju149.github.io/pubfinder.github.io/index.html"/>
          </div>

        </div>
      </section>
    );
  }

export default WebsitesList;