import React, { useState } from "react";
import styled from "styled-components";
import { Accessibility } from "./accessibility";
import { MenuToggle } from "./menuToggle";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const LinksMenu = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: #fff;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  animation: mobileMenuAnimation 0.2s;
`;

const LinksWrapper = styled.ul`
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 9px 1.1em;
  color: #222;
  font-weight: 700;
  font-size: 24px;
  display: flex;

  margin-bottom: 10px;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: inherit;
`;

const Marginer = styled.div`
  height: 2em;
`;

export function MobileNavLinks(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <NavLinksContainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <LinksMenu>
            <LinksWrapper>
              <LinkItem>
            <Link href="/">Accueil</Link>
            </LinkItem>
            <LinkItem>
              <Link href="/portfolio">Portfolio</Link>
            </LinkItem>
            {/* <LinkItem>
              <Link href="/about">A propos</Link>
            </LinkItem> */}
            <Marginer />
            <Accessibility />
          </LinksWrapper>
        </LinksMenu>
      )}
    </NavLinksContainer>
  );
}
