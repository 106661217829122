import React from 'react';
import './blankpage.css'

const BlankPage = () => {
return (
	<div class="blank-block">
	    <span class="blank-title">Il n’y a rien ici</span>
		<br></br>
		<span class="blank-subtitle">Du contenu arivera prochainement</span>
	</div>
);
};

export default BlankPage;
