import React from "react";
import styled from "styled-components";
import { FaInstagram, FaDribbble, FaGithub, FaLinkedinIn } from 'react-icons/fa';

const AccessibilityContainer = styled.div`
  display: flex;
  margin-right: 0px;
`;

const SocialButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 0.8em;
  color: #222;
  font-size: 24px;
  background-color: transparent;
  cursor: pointer;
  transition: all 220ms ease-in-out;

  &:hover{
    color: var(--primary-color)
  }
`;

export function Accessibility(props) {
  return (
    <AccessibilityContainer>
      <a href="https://dribbble.com/juju149" target="blank"><SocialButton><FaDribbble /></SocialButton></a>
      <a href="https://github.com/juju149" target="blank"><SocialButton><FaGithub /></SocialButton></a>
      <a href="https://www.linkedin.com/in/julien-mercier-084042224" target="blank"><SocialButton><FaLinkedinIn /></SocialButton></a>
    </AccessibilityContainer>
  );
}
