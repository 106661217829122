import axios from "axios";
import styled from "styled-components";
import React from "react";
import { keyframes } from 'styled-components'
import DribbblePost from "./DribbblePost";

const StyledPlaceholder = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const LoadMore = styled.div`
  cursor: pointer;
  width: 120px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 10rem;
  border: 1px solid #DDDDDD;
  padding: 20px;
  transition: .8s;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover{
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    transform: translate(-50%, -55%)
  }
`;

const placeholderAnimation = keyframes`
0% {
  background-position: 0% 50%;
 }
 50% {
  background-position: 100% 50%;
 }
 100% {
  background-position: 0% 50%;
 }
 `;

const StyledPlaceholderInner = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  padding-bottom: 75%;
  background: linear-gradient(
    90deg,
    #D3D3D3,
    #949494,
    #D3D3D3
  );
  background-size: 200% 200%;
  animation: ${placeholderAnimation} 3s ease infinite;
`;

function DribbblePostPlaceholder() {
  return (
    <StyledPlaceholder>
      <StyledPlaceholderInner />
    </StyledPlaceholder>
  );
}

function DribbblePosts() {
    const SHOTS_PER_PAGE = 4;
  
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingMore, setIsLoadingMore] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [postsFetched, setPostsFetched] = React.useState(false);
    const [dribbblePage, setDribbblePage] = React.useState(1);
    const [dribbblePosts, setDribbblePosts] = React.useState([]);
  
    const placeholderArr = Array.from({ length: SHOTS_PER_PAGE }, (v, i) => i);
  
    React.useEffect(() => {
      let didCancel = false;
      let dribbbleRes = {};
  
      async function getDribbblePosts() {
        try {
          if (!postsFetched) {
            dribbbleRes = await axios.get(
              `https://api.dribbble.com/v2/user/shots?access_token=6b922df5bb4c4442e7dee9cfd0b9fa863e4cfad13ecf8d95dc337906b61f6027&page=${dribbblePage}&per_page=${SHOTS_PER_PAGE}`,
            );
            setPostsFetched(true);
          }
  
          if (!didCancel) {
            setDribbblePosts([...dribbblePosts, ...dribbbleRes.data]);
  
            if (isLoading) setIsLoading(false);
            if (isLoadingMore) setIsLoadingMore(false);
          }
        } catch (error) {
          console.warn(error);
          if (!didCancel) {
            if (isLoading) setIsLoading(false);
            if (isLoadingMore) setIsLoadingMore(false);
            setPostsFetched(true);
            setIsError(true);
          }
        }
      }
  
      if (!postsFetched && !didCancel) {
        getDribbblePosts();
      }
  
      return () => {
        didCancel = false;
      };
    }, [
      dribbblePosts,
      postsFetched,
      dribbblePage,
      isLoading,
      isError,
      isLoadingMore,
    ]);
  
    function loadMorePosts() {
      setDribbblePage(dribbblePage + 1);
      setPostsFetched(false);
      setIsLoadingMore(true);
    }
  
    return (
      <section>
        <div class="container">
          <div class="tiles-grid">
            {isError && <p>Sorry, something went wrong...</p>}
            
            {isLoading &&
              placeholderArr.map((i) => <DribbblePostPlaceholder key={i} />)}
            {!isLoading &&
              dribbblePosts.map((post) => <DribbblePost key={post.id} post={post} />)}

            {isLoadingMore &&
              placeholderArr.map((i) => <DribbblePostPlaceholder key={i} />)}
            <LoadMore onClick={loadMorePosts}>
              {!isLoadingMore && <p>En voir plus</p>}
              {isLoadingMore && <p>Chargement...</p>}
            </LoadMore>
          </div>

        </div>
      </section>
    );
  }

export default DribbblePosts;