import React from 'react';
import './skill.css'

function Skill(props) {

    const image = props.image;
    const name = props.name;
    const delay = props.delay;

    return (
        <div class="skill" data-aos="fade" data-aos-delay={delay}>
            <div class="skill-image-block">
                <img src={image} class="skill-image" alt={name}></img>
            </div>
            <h6 class="skill-name">{name}</h6>
        </div>
    );
}

export default Skill;
