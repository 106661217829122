import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { Logo } from "../logo";
import { Accessibility } from "./accessibility";
import { NavLinks } from "./navLinks";
import { DeviceSize } from "../responsive";
import { MobileNavLinks } from "./mobileNavLinks";

const NavbarContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  padding: 0 1.5em;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 1630px;
  margin: auto;

  @media (max-width: 570px) {
    height: 175px;
  }
  @media (min-width: 1550px) {
    justify-content: space-between;
    padding: none;
  }
`;

const LeftSection = styled.div`
  display: flex;
`;

const MiddleSection = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const RightSection = styled.div`
  display: flex;
`;

const showConfetis = () => {
  document.querySelector('.confetis').style.transition = '1s'
  document.querySelector('.confetis').style.opacity = '100'
	document.querySelector('.confetis').style.display = 'block'
  setTimeout(() => {
    document.querySelector('.confetis').style.opacity = '0'
  }, 2000);
  setTimeout(() => {
    document.querySelector('.confetis').style.display = 'none'
  }, 3000);
  
}

export default function Navbar(props) {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.tablet });

  return (
    <NavbarContainer>
      <LeftSection>
        {!isMobile && <NavLinks />}
      </LeftSection>
      <MiddleSection  onClick={showConfetis}><Logo /></MiddleSection>
      <RightSection>
        {!isMobile && <Accessibility />}
        {isMobile && <MobileNavLinks />}
      </RightSection>
    </NavbarContainer>
  );
}