import React from "react";

function DribbblePost(props) {

    const key = props.key;
    const post = props.post;

    function capitalize(str){
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		return arr.join(" ");
	}

return (
	<div class="tile">
        <a href={post.html_url} target="blank">
            <div class="image-box"><img src={post.images.four_x} class="tile-img"></img></div>
            <div class="descriptions">
                <span class="tags">{post.tags.map((i) => capitalize(i)+", ")}</span>
                <h4>{post.title}</h4>
            </div>

        </a>

	</div>
);
};

export default DribbblePost;
