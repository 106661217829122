import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Routes, Route}
	from 'react-router-dom';
import Home from './pages';
import Portfolio from './pages/portfolio';
import Contact from './pages/contact';
import About from './pages/about';
import AnimatedCursor from 'react-animated-cursor';

function App() {

useEffect(() => {
	document.title = "Julien Mercier | Personnal Website";  
	}, []);

return (
	<Router>
		<AnimatedCursor 
		      innerSize={12}
			  outerSize={60}
			  color='0, 0, 0'
			  outerScale='0.6'
			  outerStyle={{border: '1px solid black', 'background-color': 'transparent'}}
		/>
		<Navbar />
		<Routes>
			<Route exact path='/' element={<Home />} />
			<Route path='/portfolio' element={<Portfolio/>} />
			<Route path='/contact' element={<Contact/>} />
			<Route path='/about' element={<About/>} />
		</Routes>
	</Router>
);
}

export default App;
