import React from "react";

function MobileTile(props) {

    const image = props.image;
    const title = props.title;
    const url = props.url;

return (
	<div class="tile">
        <a href={url} target="blank">
            <div class="image-box"><img src={image} class="tile-img"></img></div>
            <h4>{title}</h4>
        </a>

	</div>
);
};

export default MobileTile;
