import React from "react";
import './algo.css';
import BlankPage from "../../blankpage/BlankPage";

function AlgoList() {
  
    return (
      <section>
        <div class="container">
          <BlankPage />
          <div class="tiles-grid">
            
          </div>

        </div>
      </section>
    );
  }

export default AlgoList;