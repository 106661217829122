import React, {useState} from "react";
import AlgoList from "../components/portfolio/algo/AlgoList";
import BrandingList from "../components/portfolio/branding/BrandingList";
import DribbblePosts from "../components/portfolio/dribbble/DribbblePosts";
import MobileList from "../components/portfolio/mobile/MobileList";
import WebsitesList from "../components/portfolio/website/WebsitesList";
import './portfolio.css'

export function Portfolio() {

	const categoriesList = ['maquettes', 'sites web', 'applications mobiles', 'codes']
	const [category, setCategory] = useState('maquettes');

	function capitalize(str){
		const arr = str.split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		return arr.join(" ");
	}

	return (
		<main>
			<div class="container">
				<div class="categories">
					{categoriesList.map((element) => 
						<a class="category" onClick={() => setCategory(element)}>{category == element && <span class="selectedCategory">{capitalize(element)}</span>}{category != element && <span>{capitalize(element)}</span>}</a>
					)}
				</div>
			</div>
			<div class="spacer"></div>
			{ category == "maquettes" && <DribbblePosts />}
			{ category == "sites web" && <WebsitesList />}
			{ category == "applications mobiles" && <MobileList />}
			{ category == "branding" && <BrandingList />}
			{ category == "codes" && <AlgoList />}
			<div class="spacer"></div>
		</main>
	);
};



export default Portfolio;
